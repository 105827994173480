<template>
  <div class="literatureLimitDetail_container">
    <div class="literatureLimitDetail_content_block">
      <div class="literatureLimitDetail_title">
        {{ detailData.zh_title }}
      </div>

      <div class="literatureLimitDetail_content">
        <div class="literatureLimitDetail_content_img">
          <img :src="detailData.cover" />
        </div>
        <div class="literatureLimitDetail_content_info">
          <div class="literatureLimitDetail_content_tr">
            <div class="literatureLimitDetail_content_row">
              <img :src="require('@/assets/images/pb.jpg')" class="iconType" />
              <div>{{ detailData.magazine_name }}</div>
            </div>
            <div class="literatureLimitDetail_content_row">
              <img src="#" class="iconType iconhide" />
              <div class="news_if_scroll">IF：{{ detailData.if_value }}</div>
            </div>

            <div class="literatureLimitDetail_content_row">
              <img src="#" class="iconType iconhide" />
              <div>发布日期：{{ detailData.push_time }}</div>
            </div>
          </div>

          <div class="literatureLimitDetail_content_row">
            <!-- <img src="#" class="iconType iconhide" /> -->
            <el-button size="mini" @click="watchDetail"
              >点击跳转原始出处</el-button
            >
          </div>
        </div>
      </div>
      <div class="titleline"></div>
      <div class="literatureLimitDetail_desc">
        <div>
          <span class="literatureLimitDetail_desc_key"
            >标题{{ !this.showAbstract ? ": " : "：" }}</span
          >{{
            !this.showAbstract
              ? this.detailData.en_title
              : this.detailData.zh_title
          }}
        </div>
        <div v-if="isShowAbstract()">
          <span class="literatureLimitDetail_desc_key"
            >摘要{{ !this.showAbstract ? ": " : "：" }}
          </span>
          <span v-if="typeof this.abstract == 'string'">{{
            this.abstract
          }}</span>
        </div>
        <div v-if="typeof this.abstract == 'object'">
          <div v-for="item in this.abstract" :key="item[0]">
            <span style="font-weight: 600"
              >{{ item[0] }}{{ !this.showAbstract ? ": " : "：" }}
            </span>
            <span>{{ item[1] }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="literatureLimitDetail_footer">
      <el-button size="mini" @click="download"> 原文求助 </el-button>
      <el-button size="mini" @click="translateAb"
        >{{ !this.showAbstract ? "AI自动翻译" : "取消AI翻译" }}
      </el-button>
      <el-button
        v-if="detailData.new_courseware_number < imitsNumber"
        size="mini"
        :style="{
          background:
            config.buttonGroup[detailData.is_choose == 1 ? 1 : 0].background,
        }"
        @click="handleTitle"
        >{{
          config.buttonGroup[detailData.is_choose == 1 ? 1 : 0].title
        }}</el-button
      >
      <el-button
        v-else
        size="mini"
        :style="{
          background: config.buttonGroup[2].background,
        }"
        @click="handleTitle"
        >{{ config.buttonGroup[2].title }}</el-button
      >

      <!-- <el-button size="mini" @click="handleTitle">选择该文献 </el-button>
      <el-button size="mini" @click="handleTitle">选择该文献 </el-button> -->
    </div>
    <popup
      v-if="showEmailPopup"
      :cfg="emailMsg"
      @doClose="closeEmailPopup"
      @doSure="saveEmail"
    >
      <div class="popup_main">
        <div>
          <el-input
            v-model="emailValue"
            placeholder="请输入您的邮箱"
          ></el-input>
        </div>
      </div>
    </popup>

    <popup
      :cfg="msg"
      v-if="showPopup"
      @doClose="doClose"
      @doSure="downloadFile"
    >
      <div class="popup_main">
        <div>
          本平台不提供任何原文资源，仅采用百度学术文献互助平台的C2C模式，业内同道接到您的需求后会分享至您的邮箱。
        </div>
      </div></popup
    >
  </div>
</template>

<script>
import { ElButton, ElInput } from "element-plus";
import popup from "@/components/unit/popup";
export default {
  name: "headlinesDetail",
  data() {
    return {
      detailData: {},
      abstract: "",
      showAbstract: false,
      id: "",
      downloading: false,
      emailMsg: {
        title: "完善您的邮箱",
        button: {
          sure: "确定",
          cancel: "取消",
        },
      },
      msg: {
        title: "温馨提示",
        button: {
          sure: "确定",
          cancel: "取消",
        },
      },
      showPopup: false,
      showEmailPopup: false,
      emailValue: "",
      config: {
        buttonGroup: this.$config.buttonGroup,
      },
      imitsNumber: 15,
      doctorInfo: {
        email: "",
      },
    };
  },

  created() {
    this.id = this.$route.query.id;
    this.imitsNumber = this.$route.query.imitsNumber;
  },
  mounted() {
    this.doctorInfo = this.$store.state.common.doctorInfo;
    console.log(this.doctorInfo);
    this.initData();
  },
  components: {
    popup,
    ElButton,
    ElInput,
  },
  methods: {
    translateAb() {
      //摘要
      this.showAbstract
        ? (this.abstract = this.detailData.en_abstract)
        : (this.abstract = this.getAbstract());

      this.showAbstract = !this.showAbstract;
    },
    getAbstract() {
      if (/\[\[/.test(this.detailData.abstract)) {
        try {
          let abstract = eval(this.detailData.abstract);
          if (abstract[0].length == 1) {
            this.abstract = abstract[0][0];
          } else {
            this.abstract = abstract;
          }
        } catch (err) {
          this.abstract = this.detailData.abstract;
        }
      } else {
        this.abstract = this.detailData.abstract;
      }
      return this.abstract;
    },
    //双摘要问题
    isShowAbstract() {
      return !(
        typeof this.abstract == "object" &&
        this.abstract
          .map((x) => x[0])
          .flat()
          .includes("摘要")
      );
    },

    initData() {
      let url = this.$tools.getURL(this.$urls.article.articleDetail, {
        id: this.id,
        doctor_id: this.doctorInfo.id,
      });
      this.$axios.get(url).then((res) => {
        this.detailData = res.data;
        this.abstract = this.detailData.en_abstract;
      });
    },

    // 查看原文
    watchDetail() {
      this.$tips.success({ text: "正在跳转中，请稍后" });
      window.location.href = this.detailData.original_url;
    },

    //原文求助
    async download() {
      // if (!this.doctorInfo.name) {
      //   this.$router.push({
      //     path: "/information",
      //     query: {},
      //   });
      //   return;
      // }
      if (!this.doctorInfo.email) {
        this.showEmailPopup = true;
        return;
      }
      this.showPopup = true;
    },
    closeEmailPopup() {
      this.showEmailPopup = false;
    },
    saveEmail() {
      if (!/^\w+[.-\w]+@[-\w]+\.\w+$/.test(this.emailValue)) {
        this.$tips.error({ text: "请输入正确的邮箱" });
        return;
      }
      this.doctorInfo.email = this.emailValue;
      this.download();
      this.closeEmailPopup();
    },
    downloadFile() {
      if (this.downloading) return;
      let url = this.$urls.article.articleHelp;
      let data = {
        id: this.detailData.id,
        email: this.doctorInfo.email,
        doctor_id: this.doctorInfo.id,
      };
      this.downloading = true;
      this.$axios
        .post(url, data)
        .then((res) => {
          console.log(res);
          setTimeout(() => {
            this.downloading = false;
          }, 1000);
          this.doClose();
          if (res.errcode) return;
          this.$tips.success({ text: "求助成功" });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    doClose() {
      this.showPopup = false;
    },
    //选择文献
    handleTitle() {
      if (this.detailData.new_courseware_number == this.imitsNumber) {
        this.$tips.warning({ text: "该文献已选完" });
        return;
      }
      if (this.detailData.is_choose) {
        this.$tips.warning({ text: "已选择该文献" });
        return;
      } else {
        let url = this.$urls.article.chooseArticle;
        let data = {
          article_id: this.detailData.id,
          project_id: this.detailData.project_id,
          period: this.detailData.period,
          doctor_id: this.doctorInfo.id,
          course_number: this.detailData.course_number,
        };
        this.$axios.post(url, data, (res) => {
          if (res.errcode) return;
          this.detailData.is_choose = 1;
          this.$tips.success({ text: "选择成功" });
        });
      }
    },
  },
};
</script>

<style>
.literatureLimitDetail_container {
  padding: 6vw 4vw;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.literatureLimitDetail_title {
  font-size: 2vw;
  font-weight: bold;
  color: #6c6c6c;
  word-break: break-all;
  text-align: left;
  margin-bottom: 2vw;
}

.titleline {
  height: 0.26vw;
  border: 1px #d5a9bf solid;
}
.literatureLimitDetail_content_block {
  flex: 1 0 0;
  overflow: auto;
}
.literatureLimitDetail_content {
  display: flex;
  padding-bottom: 4vw;
  width: 100%;
}
.literatureLimitDetail_content_img {
  height: 40vw;
  flex: 26.66vw 0 0;
}
.literatureLimitDetail_content_img img {
  height: 40vw;
  width: 26.66vw;
}
.literatureLimitDetail_content_info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 4vw;
  word-break: break-all;
}
.literatureLimitDetail_content_row {
  font-size: 3.73vw;
  color: #606060;
  line-height: 4.8vw;
  margin: 1vw 0;
  display: flex;
}
.literatureLimitDetail_content_row:last-child {
  margin-bottom: 0;
}
.literatureLimitDetail_content .iconType {
  width: 4.26vw;
  height: 4.26vw;
  border-radius: 2.14vw;
  margin-right: 2vw;
}
.literatureLimitDetail_content .iconhide {
  visibility: hidden;
}
.literatureLimitDetail_desc {
  padding: 2.66vw 0 0;
  font-size: 4vw;
  line-height: 7vw;
}
.literatureLimitDetail_desc_key {
  font-weight: 700;
  color: #b23c70;
}
.literatureLimitDetail_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4vw;
}

.literatureLimitDetail_container .el-button {
  height: 28px;
  color: #fff;
  border: none;
  font-size: 12px;
  background: #9d004a;
}

.share_modal {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.share_modal img {
  width: 70vw;
  height: 32vw;
  position: absolute;
  top: 0;
  right: 0;
}
.literatureLimitDetail_container ::-webkit-scrollbar {
  display: none;
}

.literatureLimitDetail_container .popup_main {
  font-weight: bold;
  border-radius: 2vw;
}
.literatureLimitDetail_container .popup_title,
.popup_button_sure {
  color: #9d004a;
}
.literatureLimitDetail_container .popup_main .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 100%;
  border-radius: 2vw;
}
</style>